import { assertValidExecutionArguments } from 'graphql/execution/execute';
import Ajax from './Ajax';

function fetchData(url, {uts1, uts2, vars, dataType, mem = 0}, opts) {

    if (dataType == 'averages') {
        //dataType = 'pgrst_ct_' + dataType;
    }

    if (dataType == 'samples') {
        dataType = 'jsw_' + dataType;
    }
    
    
    // we expect every parameter to be valid, so we do not need to validate parameters 
    return Ajax.promise_jsonp(url + "/data/" + dataType, {data: {
        start: uts1, end: uts2, vars: vars.join(','),
        type: 'chart', mem: mem
    }})
}

export default fetchData;