import { Icon as LeafletIcon } from 'leaflet';
import {mapUnitNameToTag, mapParTypeNameToTag, decimals} from 'app/legacy/react/core/CoreUtils.jsx';

var Moment = require('moment');
Moment.locale('pl'  );

const IconSize = [32, 32];

const colors = [
  "#62B100",// "rgb(0,255,255)",
  "#B3DD00",//  "rgb(201,218,248)",
  "#FBD900", //"rgb(0,255,0)",
  "#DE8000", //"rgb(255,255,0)",
  "#DB0000", //"rgb(255,153,0)",
  "#920000", //"rgb(255,0,0)"
];

const labelOrder = [
  "pm10", "pm25", "h2s", "nh3", "parametr", "temp", "press", "humid", "wd", "ws"
]


const BlackIndexIcons = [
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/black/01.svg').default,
    iconSize:    IconSize
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/black/02.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/black/03.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/black/04.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/black/05.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/black/06.svg').default,
    iconSize:    IconSize,
  })
]

var BlackUndefinedIcon =   new LeafletIcon({
    iconUrl: require('../../icons/SVG/black/07.svg').default,
    iconSize:    IconSize,
});



const IndexIcons = [
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/01.svg').default,
    iconSize:    IconSize
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/02.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/03.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/04.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/05.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/SVG/06.svg').default,
    iconSize:    IconSize,
  })
]

var UndefinedIcon =   new LeafletIcon({
    iconUrl: require('../../icons/SVG/07.svg').default,
    iconSize:    IconSize,
});


function getIndexColor(level) {
  return (typeof level !== "undefined" ? colors[level] : "grey");
}  


function getIndexIcon(loc, infoData) {
  var icons = IndexIcons;
  var uicon = UndefinedIcon;

  console.log('getting icon for quality inddx: ', loc, infoData.qualityIndex)


  return typeof infoData.qualityIndex != "undefined" && infoData.qualityIndex < icons.length ? icons[infoData.qualityIndex] : uicon;
}


function getBlackIndexIcon(loc, infoData) {
  var icons = BlackIndexIcons;
  var uicon = BlackUndefinedIcon;

  return typeof infoData.qualityIndex != "undefined" && infoData.qualityIndex < icons.length ? icons[infoData.qualityIndex] : uicon;
}

function generateWdName(wd) {
  var src = null;
  //var alphabet = ['N', 'NNW', 'NW', 'NWW', 'W', 'SWW', 'SW', 'SSW', 'S', 'SSE', 'SE', 'SEE', 'E', 'NEE', 'NE', 'NNE', 'N'];
  var alphabet = ['NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];

  var c = Math.floor(wd / 45);

  return alphabet[c];
}


const Info = ({ctx, loc, data}) => {
  console.log('my data wtf: ', ctx, loc, data, new Date(data.timestamp * 1000))

  var qualityIndexColor = getIndexColor(data.qualityIndex);
  return (
    <div style={{minWidth: 200}}>
      <div style={{borderTop: '3px solid ' + qualityIndexColor }} title={loc.name}>
        <div style={{textAlign: 'center', width: '100%'}}><font size={"10px"} style={{fontWeight: "bold"}}>{loc.comment}</font></div>
        <div style={{textAlign: 'center', width: '100%'}}><font size={"10px"}>{loc.name}</font></div>
        <div style={{textAlign: 'center', width: '100%', marginTop: 5, marginBottom: 5}}><b>{new Moment(new Date(data.timestamp*1000)).format("YYYY-MM-DD HH:mm")}</b></div>
        <table cellPadding={1} style={{fontSize: 14}}>
          <tbody>
          <tr>
            {loc.site == 'w1214' ? <><td></td><td></td></> : 
            <>
            <td>powietrze</td>
            <td>{":"}</td><td colSpan={2}><font size={"12px"} style={{whiteSpace: 'nowrap', color: qualityIndexColor}}><b>{data.qualityIndexDescription}</b></font></td>
            </>
            }
          </tr>
          {
            (
              ctx.getSiteMeasurements(loc.code)
            ).sort((a, b) => labelOrder.indexOf(a.compound_type) - labelOrder.indexOf(b.compound_type))
            .map((meas) => {
              var short = meas.compound_type;
              //var code = "_" + meas.code;
              
              if (short == "parametr") {
                short = meas.code.split('.')[1];
              }

              if (data.hasOwnProperty(short)) {
                var unit = meas.unit_id;
                var index = short;

                console.log('my value: ', short, data[short], data )
                return (
                  short == "wd" ?
                  <tr key={index}><td>{mapParTypeNameToTag(short.toUpperCase())}</td><td>{":"}</td><td colSpan={2}>{(data[short] ? generateWdName(decimals(data[short].value, 1)) : "-")}</td></tr>
                  : <tr key={index}><td>{mapParTypeNameToTag(short.toUpperCase())}</td><td>{":"}</td><td>{(data[short] ? decimals(data[short].value, 1) : "-")}</td><td>{mapUnitNameToTag(unit)}</td></tr>
                )
              } else {
                return null;
              }
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}


const AltUndefinedIcon = UndefinedIcon;
const AltIndexIcons = IndexIcons;

export {
  getIndexIcon, getBlackIndexIcon, IndexIcons, AltIndexIcons, UndefinedIcon, AltUndefinedIcon, getIndexColor, BlackIndexIcons, BlackUndefinedIcon, Info
}
