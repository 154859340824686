
import React, {Component} from 'react';
import { decimals } from '../../../legacy/react/core/CoreUtils';

const numberStyle = {
    textAlign: 'center', padding: 10, fontSize: '28px', fontWeight: 'bold',
    //border: '1px solid black'
};

const headerStyle = {
    textAlign: 'center', padding: 5, fontSize: '18px', fontWeight: 'bold',
    //border: '1px solid #eee',
    borderRadius: 10, background: '#eee', borderColor: '#eee'
}

const unitStyle = {
    textAlign: 'center', padding: 10, fontSize: '28px', fontWeight: 'bold',
    //border: '1px solid black'
}

const textStyle = {
    textAlign: 'center', padding: 10, fontSize: '24px', fontWeight: 'bold',
    //border: '1px solid black',
    borderRadius: 10,

    background: '#eee', color: 'black'
}

const rowStyle = {
    //border: '1px solid grey'
}

const CenteredText = ({text}) => <tr key={"ht"}><td key={"t"} colSpan={2} style={textStyle}>{text}</td></tr>
const HeaderedValue = ({header, value, unit}) => [
    <tr key={"h"} style={rowStyle}>
        <td key={"v"} colSpan={2} style={headerStyle}>{header}</td>
    </tr>,
    <tr key={"v"} style={rowStyle}>
        <td key={"v"} colSpan={2} style={numberStyle}>{value}<span style={unitStyle} key={"u"} dangerouslySetInnerHTML={{__html: unit}}></span></td>
    </tr>
];

const TableView = ({viewConf, data}) => {
    console.log('generating: ', viewConf, data)

    return (
        <table key={"t"} cellSpacing={1} style={{width: '100%', height: '100%'}}>
            <tbody key={"b"}>
                {
                    viewConf.map((v, i) => {
                        var value = v.compound ? decimals(data[v.dataKey], v.compound.decimals || 1): data[v.dataKey];
                        console.log('my value: ', value, v, v.compound && v.compound.decimals)
                        return (
                            v.type === "value" ? <HeaderedValue key={i} {...v} value={value}/> : <CenteredText key={i} {...v} text={value}/>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default TableView;