import React, {Component, PropTypes} from 'react';
import ReactDOM from 'react-dom';


import ChartSeries from '../chart/ChartSeries.jsx';
import LineChart  from '../chart/LineChart.jsx';

import {
  mapParTypeName, mapParTypeNameToTag, mapUnitNameToTag, mapUnitName, tickFormatter, LegendComponent, ColorTable
} from '../core/CoreUtils.jsx';

import { decimals } from '../../../legacy/react/core/CoreUtils';


var d3 = require('d3');

//require('react-d3');


function createSerie(configObject) {
  var {fieldName, id} = configObject;

  // applying some default values
  configObject.scale = Object.assign(
    {  type: 'dynamic', visible: false, tickSize: 5}, configObject.scale
  );

  configObject.timeline = Object.assign(
    {
      type: 'dynamic',
      nice: true,
      format: '%H:%M',

    //  formatter: tickFormatter,
      ticksCount:  10,

      //range: staticRange
    },
    configObject.timeline
  )

  configObject.style = Object.assign(
    { stroke: ColorTable[id], "stroke-width": 1.5 }, configObject.style
  );


  //console.log('creating serie: ', configObject, id)
  return ChartSeries.createLineSerie(
    // default values for serieConfig
    Object.assign({
      // series data
      data: null,
      // accessor functions
      timeAccessor: (d) => { return d.t * 1000},
      // value accessor
      valueAccessor: (d) => { return parseFloat(d[fieldName])},
      // serie name
      id: null,

      // fieldName
      fieldName: null, name: null,
      // legend names
      legendName: null,
      shortLegendName: null,

      // drawing type
      drawType: "line"
    },

    // user specified configuration
    configObject)
  )
}

var reactiveDescription = {
  width: {
    760: {
      timeline: {
        ticksCount: 8
      },
      scale: {
        tickSize: 5
      },
      legendSize: 'full',
      containerHeight: 1
    },
    500: {
      timeline: {
        ticksCount: 5
      },
      scale: {
        tickSize: 3
      },
      legendSize: 'compact',
      containerHeight: 0.8
    },
    200: {
      timeline: {
        ticksCount: 3
      },
      scale: {
        tickSize: 3
      },
      legendSize: 'compact',
      containerHeight: 0.5
    },
    0: {
      timeline: {
        ticksCount: 2
      },
      scale: {
        tickSize: 2
      },
      legendSize: 'compact',
      containerHeight: 0.3
    }
  }
};

const Chart = ({data, code, fmt, avg, color, dataProvider, displayLegend, disableMouseEvent, onMouseMoveHandler, selectionData, viewMode}) => {
  var height = 300;
  var chartWidth = '100%';
  //var disableMouseEvent = false;
  var c = code;
  var format =  fmt ? fmt: '%a %H:00';
  var max = 100;
  
  

  if (avg == "10s" || avg == "10m") {
    format = "%H:%M:%S";
  }

  
  if (avg == "24h") {
    format = "%d %b"
  }

  var codes = null;

  if (Array.isArray(code)) {
    codes = code;
  } else {
    codes = [ code ];
  }

  var series = []
  var legendProps = {
    title: "",
    series: series
  };

  var types = {
    "pm10" : {color: "#aaa", drawType: "line", decimals: 0, sharedExtentBase: 0, sharedExtentCeil: 200},
    "pm2.5" : {color: "#ddd",drawType: "line", decimals: 0, sharedExtentBase: 0, sharedExtentCeil: 200},
    "pm1" : {color: "black", drawType:"line", decimals: 0, sharedExtentBase: 0, sharedExtentCeil: 200},
    "temp" : {color: "rgba(255, 10, 10, 0.7)", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 50},
    "humid" : {color: "rgba(68, 160, 232, 0.7)", drawType:"line", decimals: 1, sharedExtentBase: 60, sharedExtentCeil: 100},
    "press" : {color: "rgba(255, 213, 0, 0.7)", drawType:"line", decimals: 0, sharedExtentBase: 980, sharedExtentCeil: 1100},
    "ws" : {color: "rgba(68, 160, 232, 0.7)", drawType:"area", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 25},
    "wd" : {color: "blue", drawType:"arrows", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 360},

    "rain" : {color: "rgba(68, 160, 232, 0.7)", drawType:"area", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 10},
    "humid" : {color: "#2AFF37", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 100},

    "so2" : {color: "rgba(255, 213, 0, 0.7)", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},
    "no2" : {color: "rgba(68, 160, 232, 0.7)", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},
    "no" : {color: "rgba(255, 10, 10, 0.7)", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},
    "nox" : {color: "#BA0AFF", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},
    "co" : {color: "#FFC70D", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 700},
    "o3" : {color: "#54CC14", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},
    //"h2s" : {color: "#E85902", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},
    "bzn" : {color: "#E85902", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},

    // default 
    "default" : {color: "#E85902", drawType:"line", decimals: 1, sharedExtentBase: 0, sharedExtentCeil: 500},
  }

  var isWind = false;

  //console.log('drawing data: ', data)
  //console.log('drawing chart for codes: ', codes)
  codes.forEach((c, i) => {
    var meas = dataProvider.getMeasByCode(c);

    //console.log('my meas: ', meas, c)
    var type = meas.compound_type;

    if (!types[type]) {
      type = "default";
      types["default"].color = ColorTable[(i) % (ColorTable.length)];
    }
      types[type].color = ColorTable[(i) % (ColorTable.length)];
    
    if (["ws", "wd"].includes(type)) {
      isWind = true;
    }

    //console.log('generating color: ', i, color ? (typeof color == "number" ? ColorTable[(color + i) % (ColorTable.length)] : color) : ColorTable[i])
    series.push(createSerie({
      id: i,
      style: {
       // stroke: color ? (typeof color == "number" ? ColorTable[(color + i) % (ColorTable.length)] : color) : ColorTable[i]
       stroke: types[type].color 

      },
      name: c,
      fieldName: c,
      data: data,
      legendName: c,
      shortLegendName: c,
      drawType: types[type].drawType,
      timeline: {
        ticksCount: 5,
        format: format,
        nice: false
      },

      sharedExtentBase: types[type].sharedExtentBase,
      sharedExtentCeil: types[type].sharedExtentCeil,
      reservedSpaceCeil: isWind ? 30 : 0,
      
      scale: {
        type: 'dynamic',
        visible: type === "ws" ? true :  i < 1,
        //range: [0, 100]
      },
      valueAccessor: (d) => {
        return decimals(parseFloat(d[c]), types[type].decimals);
       },
    }))

    // this absolutely sucks 
    // i need a better way to set this parameter !
    if (type === "ws" || i < 1) {
      legendProps.title = mapUnitNameToTag(meas.agg_unit);
    }
  });

  var legend = {
    // LegendComponent: <div key="legend"></div>,
    //legendProps: {title: mapUnitNameToTag(meas.agg_unit), series: [s]}
  };

  if (true ||displayLegend) {
    legend = {
      LegendComponent : LegendComponent,
      legendProps: legendProps
    }
  }

  return (
    <LineChart
      viewMode={viewMode}
      selectionData={selectionData}
      onMouseMoveHandler={onMouseMoveHandler}
      containerHeight={height}
      //reactiveDescription={reactiveDescription}
      disableMouseEvent={disableMouseEvent}
      {...legend}
      reserved_space_ceil={series[0].reservedSpaceCeil}
      shared_extent={!isWind}
      shared_extent_base={series[0].sharedExtentBase}
      shared_extent_ceil={series[0].sharedExtentCeil}
      series={series}
    />
  )
};

export default Chart;
