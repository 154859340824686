import React, {Component} from 'react';
import { MeteoPanelContext } from '../context/MeteoPanelContext';
import { Query } from 'react-apollo';

// legacy utilities libraries
import {
    buildSerieSpec, dateToUts, roundDate, nextDate, buildQueryVars, decimals
} from '../../fey/data/FeyUtils';

// import queries
import createChartDataQuery from '../query/client/ChartData';
 import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'

// import new components
import TableView from './TableView';


// Moment js
var Moment = require('moment');
Moment.locale('pl');

Moment.updateLocale('pl', {
    months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
    monthsShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru']

})


var loaderImage = require('app/images/chart-loader.gif').default;

// var LoaderComp = () => <div style={{border: '1px solid black',height: 600}}>
    {/* <img style={{ position: 'relative', left: '47%', top: '50%', marginLeft: -80, marginTop: -10 }} src={loaderImage}/> */}
{/* </div>; */}

var LoaderComp = () =>
    <Dimmer active inverted>
        <Loader inverted>Ładowanie danych...</Loader>
    </Dimmer>


class LiveDataTable extends Component {
    
    state = {
        tableData: null
    }
    
    generateView(ctx, { loading, error, data, context }) {
        if (error) return `Error! ${error.message}`;

        // if (loading) {
        //     return <LoaderComp/>
        // }
        let params = [loading, data, ctx.ui.viewMode, ctx.ui.historyMode.now1h, ctx.now10m ];

        if (this.memoizedComponent) {
            if (this.lastParams && !this.lastParams.some((v, i) => v !== params[i])) {
                // we should use memoized component 
                return this.memoizedComponent;
            }
        }

        // save last params 
        this.lastParams = params;

    
        if (!loading && data) {
            
            var lastValue = data.chartData[data.chartData.length - 1];

            console.log('what is my chartData: ', lastValue)
    
            var properValue = false;

            // check for proper value 
            for (var p in lastValue) {
                if (p !== 't' && p !== '__typename') {
                    if (lastValue[p] !== '') {
                        // we assume that there is a value for this param 
                        properValue = true;
                    }
                }
            }


            if (properValue) {
                
                // save data on which we based hour render 
                this.lastChartData = data.chartData;

                // i could check if data is valid 
                this.lastTableData = Object.assign({}, this.tableViewData, lastValue, {
                    date: Moment(lastValue.t * 1000).format('YYYY-MM-DD'),
                    //date: Moment(lastValue.t * 1000).format('l'),
                    //time: Moment(lastValue.t * 1000).format('HH:mm:ss')
                    time: Moment(lastValue.t * 1000).format('HH:mm:ss')
                });
            }
        }

        var viewConfig = this.props.viewConfig;

        if (this.lastTableData) {

            // we should do it only ones 
            viewConfig = this.props.viewConfig.map((v) => {
                var meas = ctx.getMeasByCode(v.dataKey);
                var compound;

                if (meas) {
                    compound = ctx.getCompoundType(meas.compound_type);
                }

                return {
                    ...v, meas, compound
                }
            });
            
        }

    
        return (
            this.memoizedComponent = <TableView viewConf={viewConfig} data={this.lastTableData || this.props.defaultViewData} /> 
        )
    }

    render() {

        var {
            dataUrl, columns
        } = this.props;

        var step = '10s';

        return (
            <MeteoPanelContext.Consumer>
                {(context) => {
                    let staticData;

                    if (context.ui.viewMode == "history" && context.relevantData) {
                        console.log('my relevant data', context.relevantData)
                        staticData = context.relevantData.slice(context.relevantData.length - 2);
                    }

                    console.log('context.measurements.legth=', context.measurement.lenght, 'context.now=', context.now, context)
                    return (
                        staticData ? this.generateView(context, {loading: 0, error: null, data: { chartData: staticData }}) : 
                        (
                                context.measurement.length && context.now ?
                                    <Query query={
                                        createChartDataQuery({
                                            mem: 1,
                                            dataType: 'samples',
                                            dataUrl,
                                            uts1: context.now - 60, uts2: context.now,
                                            step, columns,
                                            vars: buildQueryVars(columns, step, context.measurement), // server query vars
                                            context // pass context to query function 
                                        })
                                    }>{this.generateView.bind(this, context)}</Query> : <LoaderComp />
                        )
                    )
                }}
            </MeteoPanelContext.Consumer>
        )
    }
}

export default LiveDataTable;